/*
 * @Author: Jerry
 * @Date: 2021-03-02 16:04:22
 * @LastEditors: Jerry
 * @LastEditTime: 2021-04-09 16:17:50
 * @Description: 订单接口
 */
import { httpGet, httpPost } from '@/axios/axios';

 // 根据订单ID获取订单详细信息
export const getOrderDetail = (params) => httpGet({
  url: '/shop/order/order/getOrderDetail',
  params: params
})

//根据订单id获取物流信息
export const findLogisticsMessage = (params) => httpGet({
  url: '/shop/order/orderlogistics/findLogisticsMessage',
  params: params
})

// 查看审核原因
export const getAuditResultByDataId = (params) => httpGet({
  url: '/shop/audit/auditdata/getAuditResultByDataId',
  params: params
})

// 供应商确认交易取消
export const buyerDealCancel = (params) => httpGet({
  url: '/shop/sellOrder/buyerDealCancel',
  params: params
})

// 采购商订单删除 旧
export const buyerOrderDelete = (params) => httpGet({
  url: '/shop/buyerOrder/buyerOrderDelete',
  params: params
})
// 采购商订单删除 新
export const deleteBuyerCancelOrder = (params) => httpGet({
  url: '/shop/order/order/deleteBuyerCancelOrder',
  params: params
})
// // 供应商订单删除
// export const sellOrderDelete = (params) => httpGet({
//   url: '/shop/sellOrder/sellOrderDelete',
//   params: params
// })
// 供应商订单删除
export const sellOrderDelete = (params) => httpGet({
  url: '/shop/order/order/deleteCancelOrder',
  params: params
})

// 供应商订单删除
export const deleteSellerCancelOrder = (params) => httpGet({
  url: '/shop/order/order/deleteSellerCancelOrder',
  params: params
})

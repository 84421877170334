<!--
 * @Author: Jerry
 * @Date: 2021-03-02 15:02:25
 * @LastEditors: Jerry
 * @LastEditTime: 2021-03-25 18:07:42
 * @Description: file content
-->
<template>
  <div>
    <!-- <el-dialog title="订单退款" :visible.sync="dialogVisible" width="30%">
      <div>
        <el-form ref="form" :model="form" :rules="rules">
          <el-form-item label="退款理由" prop="reason">
            <el-input v-model="form.reason" type="textarea"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('form')">提交申请</el-button>
      </span>
    </el-dialog> -->
    <top></top>
    <div class="contain clearfix">
      <div class="co_primary Mtp100">
        <span class="FBold F20">交易关闭申请</span>
        <el-card class="box-card Mtp15 Mbm20">
          <div class="Mbm20">
            <span class="Mrt70"
              >订单编号：<span>{{ basicInfo.orderNo }}</span></span
            >
            <span
              >订单创建时间：<span>
                {{ formatDate(basicInfo.orderTime * 1000) }}
              </span></span
            >
          </div>
          <!-- 收货人信息 -->
          <div class="Mbm20">
            <div class="shouhuo Mbm10 FBold">收货人信息</div>
            <div style="display: flex; align-items: center">
              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
                class="bg-orange co_fff wid40 Plf25 Prt25 Ptp5 Pbm5 Mrt20"
              >
                <span>{{ basicInfo.consignee }}</span>
              </div>
              <div>
                <span class="Mrt15">{{ basicInfo.address }}</span>
                <span>{{ basicInfo.mobile }}</span>
              </div>
            </div>
          </div>
          <!-- 送货清单 -->
          <div class="Mbm20">
            <div class="Mbm10 FBold">送货清单</div>
            <div class="bg_f4f4 Plf20 Prt20 Ptp20 Pbm30 clearfix">
              <div class="floatL het100 textM">
                <div class="Mtp15">
                  <img
                    style="vertical-align: middle"
                    class="het70 wid70 Prt10"
                    slot="img"
                    :src="basicInfo.goodsLogo"
                  />
                  <div class="goodsTitle">
                    <span>{{ basicInfo.goodsTitle }}</span>
                  </div>
                </div>
              </div>
              <div class="floatR het100 money">
                <span class="Prt80">{{ basicInfo.goodsSellName }}</span>
                <span class="co_f00 FBold Prt80"
                  >￥{{ basicInfo.goodsPrice }}</span
                >
                <span>X{{ basicInfo.goodsCount }}</span>
              </div>
            </div>
          </div>
          <!-- 商品总金额 -->
          <div class="Mbm20">
            <div class="moneyCount Mtp10 Mbm5 F14 floatR">
              <div class="Mbm10 FBold">
                总商品金额：￥{{ basicInfo.orderMoney }}
              </div>
            </div>
          </div>
        </el-card>
        <!-- 退款 -->
        <div class="Mbm20">
          <!-- <div class="Mbm10 FBold">退款理由</div> -->
          <el-form ref="form" :model="form" :rules="rules">
            <el-form-item
              class="text_input"
              label="申请理由"
              prop="reason"
            >
              <el-input
                :rows="4"
                v-model="form.reason"
                type="textarea"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="moneyCount Mtp20 F14 floatR">
          <div class="Mbm5">
            <el-button @click="closeSubmit()">取 消</el-button>
            <el-button type="primary" @click="submitForm('form')"
              >提交申请</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <bottom class="Mtp100"></bottom>
  </div>
</template>

<script>
import { affirmCancelOrder } from "@/api/product";
import { mapGetters } from "vuex";
import top from "../top.vue";
import { formatDate } from "@/util/util";
import { getOrderDetail } from "@/api/order";
import Bottom from "../bottom.vue";
export default {
  components: { top, Bottom },
  data() {
    return {
      query: {},
      basicInfo: {},
      row: {},
      form: {
        reason: "",
      },
      rules: {
        reason: [
          { required: true, message: "请填写理由", trigger: "change" },
        ],
      },
      // dialogVisible: false,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  mounted() {
    if (this.$route.query) {
      this.$set(this, "query", this.$route.query);
      this.$set(this.row, "orderId", this.query.orderId);
    }
    this.getOrderDetail(this.query.orderId);
  },
  methods: {
    //获取订单信息
    getOrderDetail(orderId) {
      getOrderDetail({ orderId }).then((res) => {
        console.log(res.data);
        this.$set(this, "basicInfo", res.data);
      });
    },
    formatDate: formatDate,
    closeSubmit() {
      this.$set(this, "row", {});
      this.$set(this.form, "reason", "");
      this.$router.push({
        name: "HaveSettled",
      });
    },
    submitForm(formName) {
      this.$set(this.row, "userId", this.userInfo.id);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            ...this.form,
            ...this.row,
          };
          affirmCancelOrder(data).then((res) => {
            if (res.success) {
              this.$message.success("已提交申请！");
              this.$router.push({
                name: "HaveSettled",
              });
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.goodsTitle {
  display: inline-block;
  // height: 70px;
  width: 200px;
  vertical-align: middle;
  font-size: 14px;
}
.money {
  display: flex;
  align-items: center;
}
</style>
